<template>
    <div class="stepsdemo-content">
        <i class="pi pi-fw pi-user" />
        <p>Personal Component Content via Child Route</p>
    </div>
</template>

<script>
export default {

}
</script>
